.how-its-worck {
    display: flex;
    flex-direction: column;
    max-width: 1180px;
    margin: 120px auto 0;

    &_title {
        display: flex;
        flex-direction: column;
        margin-bottom: 84px;
    }

    &_info {}
}

.hwch-info {
    display: flex;
    margin-bottom: 32px;

    &_info {
        display: flex;
        flex-direction: column;
        max-width: 760px;

    }

    &_counter {
        width: 100%;
        text-align: end;
        max-width: 570px;
        width: 120%;
        border-bottom: 2px solid #FFDD00;
    }
}

.title-och {
    font-family: Montserrat700;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    text-transform: uppercase;
    color: #000000;
}

.info-och {
    font-family: Montserrat400;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 28px;
    color: #000000;
}