.donation-modail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 1053px;
    height: 81px;
    background: linear-gradient(to right, #FFFFFF97, #ffffff15);
    border-radius: 50px;
    padding: 0 126px 0 105px;
    margin: 160px auto 0;
    -webkit-box-shadow: 0px 0px 18px -14px #000000;
    box-shadow: 0px 0px 18px -14px #000000;

   
        &>span {
            font-family: Montserrat800;
            font-size: 32px;
            text-transform: uppercase;
            color: #000000;
        }
    
    

    &_store {
        display: flex;
        justify-content: space-between;
        width: 350px;
    }

    &_store>img {
        cursor: pointer;
    }

}

.button-donation {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 271px;
    height: 54px;
    border-radius: 34px;
    border: transparent;
    font-family: Montserrat800;
    font-size: 20px;
    text-transform: uppercase;
    text-decoration: none;
    color: #ffffff;
    background: linear-gradient(91.91deg, #0524c9cc 0%, #228affcc 119.2%);
    cursor: pointer;

    &__header {
        margin-top: 89px;
        margin-bottom: 16px;
    }

}

.modail-download {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    max-width: 1181px;
    max-height: 192px;
    height: 100%;
    border-radius: 50px;
    margin: 80px 0;
    padding: 49px 68px;
    background: linear-gradient(90.3deg, #fffcfc87 6.36%, #ffffffdb 101.69%);
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        height: 100%;
        top: -4px;
        left: -4px;
        right: 0;
        bottom: 0;
        border-radius: 54px;
        padding: 4px;
        background: linear-gradient(90deg, #fffcfc87, #ffffff);
        //-webkit-mask:
        //        linear-gradient(#ffffff 0 0) content-box,
        //        linear-gradient(#ffffff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        z-index: -1;
    }

    &>span {
        font-family: Montserrat400;
        font-size: 30px;
        line-height: 36.5px;
        max-width: 392px;
    }

    &_button-download {
        display: flex;
        justify-content: space-around;
        width: 53%;
    }

    &_image {
        border-radius: 12px;
        display: block;
        max-width: 100%;
        height: auto;
    }
}

.corusel-partner {
    width: 100%;
    height: 145px;
    overflow: hidden;

    &_sceen {
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: relative;
        width: 200%;
        height: 143px;

        margin: auto 0;

        &>div {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 4100px;
            animation: corsule 60s linear infinite;

            &>img {
                height: 80px;
                margin: 0 30px;

            }
        }
    }

    &_image {
        height: 80px;
        margin: 0 30px;
    }

}

.our-support-wrapper {
    margin: 0 auto;
    max-width: 1180px;
}

.carousel-partner {
    display: flex;
    align-items: center;
    width: 100%;
    height: 77px;
    margin: 0 auto;
    overflow: hidden;

    &_image {
        height: 77px;
        margin: 0 30px;
    }
}



@keyframes corsule {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-100%);
    }
}


.modail-contact {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    max-width: 1045px;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    margin: 80px 0;
    padding: 49px 68px;
    background: linear-gradient(90.3deg, #cecece87 6.36%, #c7c6c6db 101.69%);

    &::before {
        content: "";
        position: absolute;
        height: 100%;
        top: -4px;
        left: -4px;
        right: 0;
        bottom: 0;
        border-radius: 56px;
        padding: 4px;
        background: linear-gradient(90deg, #fffcfc87, #ffffff);
        z-index: -2;
        //-webkit-mask:
        //linear-gradient(#ffffff 0 0) content-box,
        //linear-gradient(#ffffff 0 0);
        //-webkit-mask-composite: xor;
        //mask-composite: exclude;
    }

    &_info {
        display: grid;
        grid-template-columns: minmax(400px, 1fr) 4fr;
        gap: 50px;
    }

    &_input-wrapper {
        position: relative;

        &>.name-input {
            width: 100%;
            padding: 22px 46px;
            border: 2px solid #FFFFFF;
            box-sizing: border-box;
            border-radius: 32px;
            background: transparent;
            outline: none;
            margin-top: 32px;
            font-family: Montserrat400;
            font-size: 23px;
            line-height: 28px;
            color: #ffffff;
            transition: all 0.3s;

            &::placeholder {
                font-family: Montserrat400;
                font-size: 23px;
                line-height: 28px;
                color: #ffffff;
            }

            &.error {
                border-color: #ff0033;
            }
        }

        &>.email-input {
            width: 100%;
            padding: 22px 46px;
            border: 2px solid #FFFFFF;
            box-sizing: border-box;
            border-radius: 32px;
            background: transparent;
            outline: none;
            margin-top: 32px;
            font-family: Montserrat400;
            font-size: 23px;
            line-height: 28px;
            color: #ffffff;
            transition: all 0.3s;

            &::placeholder {
                font-family: Montserrat400;
                font-size: 23px;
                line-height: 28px;
                color: #ffffff;
            }

            &.error {
                border-color: #ff0033;
            }
        }
    }

    &_error {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        transform: translate(10px, 100%);
        color: #ff0033;
    }

    &_subject {
        width: 100%;
        max-width: 675px;
        padding: 22px 46px;
        border: 2px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 32px;
        background: transparent;
        outline: none;
        margin-top: 32px;
        font-family: Montserrat400;
        font-size: 23px;
        line-height: 28px;
        color: #ffffff;
        transition: all 2s;

        &::placeholder {
            font-family: Montserrat400;
            font-size: 23px;
            line-height: 28px;
            color: #ffffff;
        }
    }

    &_message {
        max-width: 1040px;
        height: 287px;
        background: transparent;
        margin-top: 32px;
        padding: 22px 46px;
        border: 2px solid #FFFFFF;
        border-radius: 32px;
        box-sizing: border-box;
        font-family: Montserrat400;
        font-size: 23px;
        line-height: 28px;
        color: #ffffff;
        resize: none;
        outline: none;
        transition: all 2s;

        &::placeholder {
            font-family: Montserrat400;
            font-size: 23px;
            line-height: 28px;
            color: #ffffff;
        }
    }

    &_button {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 49px;
    }
}

.name-input {
    border-color: #ffffff;
    transition: all 2s;
}

.email-input {
    border-color: #ffffff;
    transition: all 2s;
}

.animation-border {
    border-color: #248affcc !important;
    -webkit-box-shadow: 1px 1px 14px 6px #248affcc;
    box-shadow: 1px 1px 14px 6px #248affcc;
}

.animation-border-revers {
    border-color: #ffffff !important;
    -webkit-box-shadow: 1px 1px 14px 6px #ffffff;
    box-shadow: 1px 1px 14px 6px #ffffff;
}



.button-up {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border: 0px;
    border-radius: 30px;
    bottom: 5%;
    right: 10%;
    background: linear-gradient(91.91deg, #0524c9cc 0%, #228affcc 119.2%);
    transition: 1s;
    z-index: 100;

    &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 22px 3px #0524c9cc;
    }

    &>img {
        width: 50px;
        height: 50px;
        transform: rotate(0deg);
    }

    &__dis {
        bottom: -10%;
    }
}

.arrow {
    display: none;
}


.our-support {
    font-family: Montserrat800;
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 24px;
    text-transform: uppercase;
    margin: 0 24px 25px;
}

.info-partner {
    width: 103px;
    height: 30px;
    margin: 17px 24px 0;
    font-family: Montserrat400;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #000000;
}

@media(min-width:700px) {
    .our-support {
        font-size: 46px;
        line-height: 50px;
        margin: 175px 0 32px;
    }

    .info-partner {
        width: 154px;
        height: 15px;
        margin: 28px 40px 0;
    }

    .carousel-partner {
        height: 97px;
    }
}
